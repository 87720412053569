import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "business-site business-site--content-page" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "page-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Preloader = _resolveComponent("Preloader")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (
        !_ctx.$parent.$parent.isPageHeaderTopMenuLoaded ||
          !_ctx.$parent.$parent.isPageHeaderSubMenuLoaded ||
          !_ctx.isContentLoaded
      )
      ? (_openBlock(), _createBlock(_component_Preloader, {
          key: 0,
          class: "preloader--fullscreen"
        }))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_2, [
      _createVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createVNode("div", {
        class: "page-editor-container",
        innerHTML: _ctx.content
      }, null, 8, ["innerHTML"])
    ])
  ]))
}