<template>
  <div class="preloader">
    <div id="app-loading-img" class="preloader__inner text-center">
      <img src="/images/giftedd_logo.png" width="120" height="120" />

      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Preloader"
});
</script>
