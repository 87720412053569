
import { defineComponent } from "vue";
import Preloader from "@/components/ui/Preloader.vue";
import axios from "axios";

export default defineComponent({
  name: "ContentPage",
  components: {
    Preloader
  },
  data() {
    return {
      path: "",
      content: "",
      title: "",
      isContentLoaded: false
    };
  },
  watch: {
    "$route.params.slug": {
      handler: function(slug) {
        if (this.$route.name === "ContentPage" && slug !== undefined) {
          this.isContentLoaded = false;
          const slug = this.$route.params.slug;
          axios
            .get("getStaticTextPage/" + slug)
            .then(response => {
              this.content = response.data.text;
              this.title = response.data.name;
              this.isContentLoaded = true;
              window.scrollTo(0, 0);
            })
            .catch(() => {
              this.isContentLoaded = true;
              window.scrollTo(0, 0);
            });
        }
      },
      deep: true,
      immediate: true
    }
  }
});
